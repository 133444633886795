/* eslint-disable import/prefer-default-export */
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { IconSpeechBubble, ListingFeedbackModal, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { ListingWithAuthor } from '../../types/sharetribe/listing';
import { showListingFeedbackBuyerSatisfactionForm } from '../../util/refiner';
import { parse } from '../../util/urlHelpers';
import { LISTING_FEEDBACK_BUYER_UPDATE_EMAIL_UTM_CAMPAIGN } from '../../util/sendgrid';
import css from './ListingPage.module.css';

interface SectionListingFeedbackProps {
  className?: string;
  listing: ListingWithAuthor;
}

export const SectionListingFeedback: FC<SectionListingFeedbackProps> = (props) => {
  const { className, listing } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isModalOpen: isListingFeedbackModalOpen, closeModal: closeListingFeedbackModal } =
    useActiveModal(ModalType.ListingFeedback);

  const { currentUser } = useSelector<any>((state) => state.user) as any;

  const handleListingFeedbackBannerClick = () => {
    dispatch(setActiveModal(ModalType.ListingFeedback));
  };

  const queryParams = parse(location.search);
  if (queryParams.utm_campaign === LISTING_FEEDBACK_BUYER_UPDATE_EMAIL_UTM_CAMPAIGN) {
    const email = queryParams.contact_email;
    showListingFeedbackBuyerSatisfactionForm(!currentUser && email);
  }

  return (
    <Box>
      <ListingFeedbackModal
        isOpen={isListingFeedbackModalOpen}
        handleClose={closeListingFeedbackModal}
        handleSuccess={closeListingFeedbackModal}
        listing={listing}
      />
      <Box
        className={classNames(className, css.sectionIso)}
        onClick={handleListingFeedbackBannerClick}
      >
        <Box display="flex" flexDirection="row" width="100%">
          <Box display="flex" alignItems="center" pr={3}>
            <IconSpeechBubble className={css.sectionIcon} />
          </Box>
          <Box display="flex" flexDirection="column" mr={2}>
            <TypographyWrapper
              variant="body2"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ style: { paddingBottom: '3px' } }}
            >
              Questions about this item?
            </TypographyWrapper>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr={1}>
                <TypographyWrapper variant="body2" format={TypographyFormat.Underlined}>
                  Send feedback to the seller
                </TypographyWrapper>{' '}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
