import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useBlurStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      top: 0,
      position: 'absolute',
      zIndex: -1,
      display: 'block',
      backgroundImage: (props: any) => props.image,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
      // Anything above 45px seems to cause a bright glare on mobile
      WebkitFilter: 'blur(45px)',
      MozFilter: 'blur(45px)',
      OFilter: 'blur(45px)',
      MsFilter: 'blur(45px)',
      filter: 'blur(45px)',
      clipPath: 'inset(0 0 0 0)',
    },
  },
});

interface BackgroundBlurBoxProps {
  imageSrc: string;
  key?: string;
  children: React.ReactElement;
}

export const BackgroundBlurBox = (props: BackgroundBlurBoxProps) => {
  const { imageSrc, key, children } = props;
  const classes = useBlurStyles({ image: `url(${imageSrc})` });

  const keyToUse = key || imageSrc;

  return (
    <Box key={keyToUse} className={classes.root} height={{ md: '100%' }} width={{ md: '100%' }}>
      <Box
        // Need the !important override because it's picking up the 400px
        // even on desktop for some reason
        maxHeight={{ xs: '400px', md: '100% !important' }}
        maxWidth="100%"
        height={{ md: '100%' }}
        width={{ md: '100%' }}
      >
        {children}
      </Box>
    </Box>
  );
};
