import Box from '@material-ui/core/Box';
import React from 'react';
import TypographyWrapper, {
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { propTypes } from '../../util/types';
import { sanitizeSpaces } from '../../util/strings';

const SectionBox = ({ children }) => <Box mb={2}>{children}</Box>;

const SectionQuirksAndNotes = (props) => {
  const { listing } = props;

  const { publicData } = listing.attributes;
  const { conditionInfo } = publicData;
  const { quirks, notes } = conditionInfo || {};

  function renderQuirks() {
    return quirks.join(', ');
  }

  return (
    <Box>
      {quirks?.length > 0 && (
        <SectionBox>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            Quirks:
          </TypographyWrapper>{' '}
          <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
            {renderQuirks()}
          </TypographyWrapper>
        </SectionBox>
      )}
      {notes && (
        <SectionBox>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            Seller Notes:
          </TypographyWrapper>{' '}
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{ display: 'inline', style: { whiteSpace: 'pre-wrap' } }}
          >
            {sanitizeSpaces(notes)}
          </TypographyWrapper>
        </SectionBox>
      )}
    </Box>
  );
};

SectionQuirksAndNotes.propTypes = {
  listing: propTypes.listing.isRequired,
};

export default SectionQuirksAndNotes;
